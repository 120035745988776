import { Html, useProgress } from '@react-three/drei'

export const Loader = () => {
  const { progress } = useProgress();

  return (
    <Html className='preloader-message' center>
      Loading {progress.toFixed(2)}%
    </Html>
  )
}